@import "../../../../shared/styles/sreal_default/vars/colors.scss";
@import "../../../../shared/styles/sreal_default/vars/breaks.scss";

.kennzahlen {
  color: #fff;
}

.kennzahlen-wrapper {
  margin: 2rem 0;

  .button {
    width: fit-content;
    margin-top: 3.5rem;
    margin-bottom: 1rem;
  }
  .kennzahlen__text {
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.6;
  }
}

.kennzahl__headline {
  padding-left: 1rem;
  color: white;
}

.kennzahl__circlebar {
  position: relative;

  .kennzahl__circlebar__value {
    position: absolute;
    bottom: 0;
    right: 50%;
    color: #fff;
    transform: translate(50%, -50%);
    font-weight: bold;
    font-size: 3rem;
  }

  .kennzahl__circlebar__valuetext {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }

  .kennzahl__circlebar__icon {
    position: absolute;
    top: 0;
    left: -3rem;
    border-radius: 50%;
    background-color: #fff;
    width: 2.25rem;
    height: 2.25rem;
    svg {
      width: 1.25rem;
      height: auto;
      max-height: 1.25rem;
    }
    .kennzahl__circlebar__foodicon {
      width: auto;
    }
  }
}

.kennzahl__piechart {
  margin: 2rem;
  margin-top: 0;

  .kennzahl__piechart-wrapper {
    max-width: 300px;
    margin: 0 auto;
  }
  

  .kennzahl__piechart__legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;

      .kennzahl__piechart__legend-item {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1.5rem;
        font-size: 1.1rem;
      }

      .kennzahl__piechart--legendcolor {
        display: inline-block;
        margin-right: 0.75rem;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
      }
    
      .kennzahl__piechart--legendval {
        font-weight: 600;
        padding-right: 0.75rem;
      }
  }
}

.kennzahl__list {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .kennzahl__list__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;

    .kennzahl__list__itemval {
      font-size: 3rem;
      font-weight: 600;
      margin-top: 1rem;
      text-align: center;
    }

    .kennzahl__list__itemsub {
      font-size: 1.3rem;
      font-weight: 400;
      margin-top: 0.5rem;
      text-align: center;
    }
  }
}

.kennzahl__bars {
  display: flex;
  flex-direction: column;
  margin: 2rem;
  margin-top: 0;

  .kennzahl__bars__item {
    margin-bottom: 0.25rem;
  }

  .progressbar__bar {
    margin-top: 0.5rem;
    border-radius: 1rem;
    background-color: rgba(255,255,255,.2);

    .progressbar__bar--filled {
      background-color: #fff;
      border-radius: 1rem;
    }
    
  }
}

@media all and (min-width: $mobile_break) {
  .marktinfolight-card {
    .kennzahl__piechart {
      display: flex;
      .kennzahl__piechart-wrapper {
        width: 50%;
      }
      .kennzahl__piechart__legend {
        width: 50%;
        padding-left: 8%;
        .kennzahl__piechart__legend-item {
          display: flex;
        }
      }
    }

    .kennzahl__bars {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 50px;
      .kennzahl__bars__item {
        width: 46%;
      }
    }
  }

  .kennzahl__list {
    flex-direction: row;
    justify-content: space-around;
    margin-top: 7rem;
  }
  
  .kennzahlen {
    position: relative;
    .button {
      display: none;
    }
    .filtermenu-wrapper-left {
      //display: none;
    }
    .kennzahl__stand {
      position: absolute;
      right: 40px;
      bottom: 0;
      text-align: right;
    }
  }
  .kennzahlen-wrapper__circle-bar {
    display: flex;
    .kennzahlen__text {
      width: 50%;
    }
    .kennzahl__circlebar-wrapper {
      width: 50%;
      align-items: flex-start;
      margin-top: 1rem;
    }
  }
  .kennzahlen__text {
    margin-top: 0rem;
    padding: 0 !important;
  }
  .marktinfolight__result--withSavedInfo {
    .kennzahlen-wrapper {
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
    }
  }
} 

.kennzahl__list__wrapper__item:has(.empty) {
  display: none;
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
  .kennzahlen__text {
    margin-top: -40px;
  }
}

@media all and (max-width: $mobile_break) {
  .kennzahl__circlebar__icon {
    top: -15px !important;
    left: 0rem !important;
  }
  .kennzahlen__text, .kennzahl__stand {
    display: none;
  }
  .kennzahl__list div {
    display: none
  }
  .kennzahl__list div:first-child  {
    display: flex;
    margin-top: 2rem;
  }
  .kennzahl__piechart__legend .kennzahl__piechart__legend-item {
    display: none;
  }
  .kennzahl__piechart__legend .kennzahl__piechart__legend-item:first-child  {
    display: flex;
    margin-top: 2rem;
    margin-bottom: -3rem;
  }
  .kennzahl__bars .kennzahl__bars__item {
    display: none;
  }
  .kennzahl__bars .kennzahl__bars__item:nth-child(-n + 4) {
    display: block;
    margin-bottom: 4rem;
  }
  .kennzahl__bars .kennzahl__bars__item:nth-child(4) {
    margin-bottom: 1rem;
  }
  .kennzahl__bars {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .kennzahlen-wrapper {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .kennzahl__piechart {
    margin: 0.2rem;
    &__legend {
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
  .kennzahlen {
  .button {
    margin-left: 1rem;
  }
}

}


//Dialog 
.pagedialog {
  .kennzahlen {
    background-color: $lightstone;
    color: #fff;
    h3 {
      color: #fff;
    }
    .button {
      display: none;
    }
    .kennzahlen__text, .kennzahl__stand {
      display: block;
    }
  }
  .kennzahl__list div {
    display: flex;
    margin-top: unset;
  }
  .kennzahl__piechart__legend-item {
    display: flex !important;
    margin-top: unset !important;
    margin-bottom: 1.5rem !important;
  }
  .kennzahl__bars .kennzahl__bars__item {
    display: block;
    margin-bottom: 3rem;
  }

}