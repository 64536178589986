@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

#header:has(.header__image) {
  height: 37rem;
}

#header {
  .recharts-cartesian-axis-line, .recharts-cartesian-axis-tick-line {
    stroke: white;
  }
  
  padding: 0.8rem 0.8rem 0;
  display: inline-block;
  color: #fff;
  width: 100%;
  height: 15rem;
  min-height: 210px;
  margin: 0;
  z-index: 100;
  position: relative;

  /* CONTENT */
  .header__content-wrapper {
    width: calc(100% - 1.5rem);
    margin: 1rem auto;

    h1 {
      font-size: 1.5rem;
    }

    p {
      max-width: 640px;
    }

		ul {
			text-align: left;
			font-size: 1.15rem;

			&.list--withcheckmark {
				li {
					background-position: left 12px;
					padding-left: 1.4em;
					line-height: 2.5;
				}
			}
		}
  }

  .header__content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }

  &.z-index-1 {
    z-index: 1;
  }

  &.z-index-999 {
    z-index: 999;
  }

  &.z-index-9999 {
    z-index: 9999;
  }
	
  &.header--small {
    height: 6rem;
    min-height: unset;
  }

  &.header--default, &.header--slider {
    background-color: $erste-blue;
    min-width: 100%;

		&.header--verkaufen, &.header--kaufen {
			.header__content-wrapper {
				top: calc(50% + 2rem);
				transform: translateY(-50%);
			}
		}

    /* CONTENT */
    .header__content-wrapper {
      display: inline-block;
      position: absolute;
      top: 40%;
      width: calc(100% - 3.5rem);
      height: auto;
      z-index: 2;
      box-sizing: border-box;

      &.header__content-wrapper--bottom {
        transform: unset;
      }

      &#header__suchprofile {
        position: relative;
        transform: unset;
        top: 0;
        margin-left: 0.2rem;

        margin-top: 2rem;
        width: calc(100% - 3rem);

        .header__suchprofile--top {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          position: relative;

          h1 {
            margin: 0;
            padding-right: 3rem;
            white-space: nowrap;
          }

          p.desktop-hidden {
            min-width: 100%;
          }

          .button {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
          }

          .filtermenu-wrapper-right,
					.filtermenu-wrapper-none {
            width: 100%;
            margin-top: 3rem;
            margin-left: 0;
          }
        }
        

          .immo-card,
          .searchprofil-withoutimmos-card {
            max-height: 470px;
            max-width: 350px;

            .topImage_icon {
              height: 185;
            }

            .card__content {
              position: relative;
              min-height: 250px;
              padding-bottom: 4rem;

              .immo-card__bottom {
                position: absolute;
                bottom: 1.5rem;
                width: calc(100% - 3rem);

                &.w-100 {
                  width: 100%;
                }
              }
            }

            h2 {
              color: $erste-darkblue;
              font-size: 1.5rem;
            }

            p, span {
              color: $erste-darkblue;
            }

            .topImage_icon {
              svg {
                height: 10rem;
                width: 7rem;
              }
            }
        }

        // suchprofile carousel
        .carousel {
          margin-right: 0;

          .slick-slider {
            position: static !important;
          }

          .slick-list {
            overflow: visible;
          }

          .slick-slide, .immo-card .card {
            height: 480px;
          }

          .immo-card .card .immo-card__bottom {
            bottom: 0rem;
          }

          .slick-slide:last-child {
            padding-right: 1rem;
          }

          .slick-dots {
            margin-top: 2rem;
          }
        }

        @media all and (min-width: $mobile_break) {
          max-width: $max_tablet_width;

          .header__suchprofile--top {
            flex-wrap: nowrap;
            align-items: flex-start;

            .header__suchprofile--topfiltermenu {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              align-items: center;
              justify-content: flex-end;

              h1 {
                margin-top: 0;
              }

              .filtermenu-wrapper-right,
              .filtermenu-wrapper-none {
                width: auto;
                margin: 0.5rem 0;
              }

              .button {
                margin-left: 2rem;
                position: relative;
              }
            }
          }
        }

        @media all and (min-width: 1010px) and (max-width: $desktop_break) {
          .header__suchprofile--top {
            .header__suchprofile--topfiltermenu {
              position: relative;
              right: 40px;
            }
          }
        }

        @media all and (min-width: $mobile_break) {
          max-width: $max_desktop_width;
          .header__suchprofile--top {
            h1 {
              width: auto;
            }
          }
        }
      }

      &#header-dashboard4 {
        display: flex;
        gap: 1.5rem;
        left: 0;
        right: 0;
        flex-direction: column;
        align-items: center;
        h1 {
          margin-bottom: 0;
        }
        svg {
          margin-top: 0rem;
        }
        p {
          max-width: unset;
          position: absolute;
          text-align: center;
          width: 100%;
          top: 26rem;
        }
      }
    }
  }

  &.header--immodetail {
    min-height: auto;
    height: auto;
    margin-bottom: 1rem;
  }

  &.header--darkbluebg {
    background-color: $erste-blue-hover;
  }
  &.header--purplebg {
    background-color: $purple;
  }
  &.header--turquoisebg {
    background-color: $turquoise;
  }
  &.header--pinkbg {
    background-color: $pink;
  }
	
  &.header--greenbg {
    background-color: $green;

    .slick-dots li.slick-active button {
      background-color: #fff;
      border-color: #fff;
    }
  }

  &.header--darkbluebg,
  &.header--greenbg {
    .header__content-wrapper {
      h1,
      h2,
      h3,
      p,
      span,
      .textlink {
        color: #fff;
      }
    }
  }

  &.header--stats {
    position: relative;
    min-height: 600px;

    h1 {
      color: #fff;
    }
  }

  &.header--stats-2 {
    .statistic-card-wrapper {
      width: 100vw;
    }
    .slick-dots {
      margin: -2rem 0;
    }
  }

  &.header--stats-final {
    .slick-slider {
      height: 56%;
    }
    .slick-track {
      height: 85%;
    }
    .slick-dots li.slick-active button {
      background-color: #fff;
      border-color: #fff;
    }
    .slick-dots li button {
      background-color: rgba(255, 255, 255, 0.3);
      border-color: transparent;
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
    }
    .slick-slide > div {
      height: 100%;
    }
    .statsheader-container--slider {
      height: 100%;
      h1 {
        font-size: 2.5rem;
      }
    }
    .statsheader-container {
      .statsheader-container--final__left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        .statistic-card {
          height: 100%;
          border-radius: 12px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
      .statsheader-container--final__right {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
        .statistic-card {
          height: 100%;
          border-radius: 12px;
          text-align: center;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
    }
  }

  

  &.header-overflowhidden {
    overflow: hidden;
  }

  svg.header__iconbg {
    height: 80px;
    margin: 2rem auto;
    width: 100%;
  }

  /* NAV */
  #sreal-header {
    max-width: $max_header_width;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: $erste-blue;
    padding: 0.8rem;
    margin: -0.3rem;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;

    .header__logo,
    .header__logo img {
      height: 100%;
      max-height: 24px;
      margin: 8px 0.8rem 0 0rem;
      width: auto;
      cursor: pointer;
    }

    .header__userpic {
      display: none;
    }
  }

  /* IMAGE */
  .header__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }

  svg.header__image {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 50%;
  }

  /* CONTENT */
  .header__content-wrapper {
    display: inline-block;
    position: absolute;
    top: 70%;
    width: calc(100% - 3rem); // 2 * 2.5rem margin
    height: auto;
    z-index: 2;
    box-sizing: border-box;
  }
 
  @media all and (min-width: $mobile_break) {
    &.header--small {
      height: 11rem;
    }
  }

  /* CAT BUTTONS */
  .header__ctabuttons {
    .button {
			display: inline-block;
      margin-top: 1rem;
			margin-right: 0.5rem;
			margin-left: 0.5rem;
      box-sizing: border-box;
    }

    a:last-child .button {
      margin-bottom: 1rem;
    }
  }

  @media all and (min-width: $mobile_break) {
    --widthcalc: calc(100% - 930px);
  }

  @media all and (min-width: $desktop_break) {
    --widthcalc: calc(100% - 1200px);
  }
}

.header__similar-immos {
  background-color: #af7ead;
  padding: 1rem 3.2rem 3.2rem;
  padding-bottom: 0;

  .header__similar-immos__header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .header__similar-immos__header {
      display: inline-block;
      max-width: 1315px;
      width: 100%;

			> span {
				/* headline */
				margin-left: calc(50px + 1rem + 0.25rem); // button width + gap + card-margin
			}
    }
  }
}

@media all and (max-width: $mobile_break) {
  .header__similar-immos {
    padding: 0 1rem 0 1rem;
    padding-bottom: 1rem;
    .header__similar-immos__inner {
      display: block;
      .header__similar-immos__header {
        display: inline-block;  
        margin-top: 1rem;

				> span {
					/* headline */
					margin-left: 0.5rem;
				}
      }
    }
  }
}

.fslightbox-open {
  #header {
    z-index: 1;
  }
}

#sreal-kundenportal-root.is-blurred {
  #sreal-header {
    > .header__logo {
      visibility: hidden;
    }
  }

	.header--overlapped {
		z-index: 100 !important;
	}
}

/* Headlines Desktop */
.header__desktopheadlines {
  display: flex;
  width: calc(100% - 3rem);
  max-width: $max_tablet_width;
  margin: 0 auto;
  align-items: center;
  white-space: nowrap;
  div {
    width: fit-content;
    h2,
    h3 {
      margin-top: 0;
    }
  }
}

@media (min-width: $desktop_break) {
  .header__desktopheadlines {
    max-width: $max_desktop_width;
  }
}

// 750px
@media (min-width: $mobile_break) {
  #header {
		padding: 3.2rem 3.2rem 0;
    #header__suchprofile {
      margin-left: 0rem;
    }
    .header__desktopheadlines {
      .header__desktopheadlines__iconDesktop {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        svg.header__iconbg {
          width: fit-content;
          margin: 0;
        }
      }
    }
    .header__ctabuttons {
      .button {
        margin-right: 1rem;
        margin-top: 3rem;
      }
    }

    #sreal-header {
      .header__logo {
        margin-left: 0.8rem;
      }

      .header__buttonright {
        display: block;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);

				.button--register-menu {
					margin-left: 1rem;
				}
      }

      .header__nav {
        .header__nav__link {
          text-decoration: none;
        }

        li {
          margin-bottom: 0.7rem;      
          padding: 0.3rem 0.8rem 0.15rem 0.8rem;
          .header__nav__sub {
            li {
              padding-bottom: 0rem;
              border: none;
              padding: 0;
              margin: 0;
            }
            li:hover {
              background-color: transparent;
            }
          }
        }
        li:has(.header__nav__sub) {
          padding-bottom: 0.45rem;
          margin-bottom: 0.4rem;
          text-wrap: nowrap;
        }
        li:hover {
          border-bottom-color: #fff;
        }
      }
    }

    /* CONTENT */
    .header__content-wrapper {
      max-width: 900px;

			&.w-100 {
				max-width: 100%;
			}
      h1,
      h2 {
        font-size: 2rem;
      }
    }


    .header__ctabuttons-wrapper {
      display: block;

      .header__ctabuttons {
        position: relative;
        background-color: transparent;

        .button {
          width: auto;
          margin-left: 0;
        }
      }
    }
  }
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
  .slick-slider {
    .header__content-wrapper {
      max-width: 1170px;
      padding-left: calc(var(--widthcalc) / 2);
      padding-right: calc(var(--widthcalc) / 2);
    }
  }
  #header {
    .header__content-wrapper {
      left: calc((100% - $max_tablet_width) / 2);
    }
  }
}

// 1200px
@media (min-width: $desktop_break) {
  #header {
    padding-top: 2rem;

    #sreal-header {
      margin: 0 auto;
      flex-direction: column;
      align-items: flex-start;
      padding: 1rem;
      padding-bottom: 0;

      .header__buttonright--desktop, nav {
        display: block;
        cursor: pointer;
      }
      nav.header__nav--mobile {
        display: none;
      }

			.header__buttonright {
				display: flex;
				flex-direction: row;
				align-items: center;
				top: 33%;
				
				.header__userpic {
					display: block;
					margin-top: 1rem;
				}

				.buttonswitcher--wrapper {
					margin-right: 1rem;
					margin-top: 1rem;
				}
			}
    }

    .header__content-wrapper {
      left: calc((100% - $max_desktop_width) / 2);
      max-width: $max_desktop_width;
      margin-left: 0;
      margin-right: 0;
      h1,
      h2 {
        font-size: 2.5rem;
        width: 47%;
        margin-bottom: 4rem;
      }

      .card__content {
        h2 {
          width: 100%;
        }
      }
    }

    .header__content {
      max-width: $max_desktop_width;
      margin: 0 auto;
    }
  }
}

@media all and (min-width: $mobile_break) {
  .header--overlapped {
    margin-bottom: -7rem !important;
    z-index: 1 !important;
  }
  .header--darkblue-withouticon {
    height: 359px !important;
  }

  .headline--mobile {
    display: none;
  }
  .cardgrid {
    .header__suchprofile--immos {
      width: 30%;
      margin: 0rem;
    }
  }
  .header__content-wrapper {
    margin-left: 0rem !important;
    h1,
    p {
      color: $erste-darkblue;
    }
    h1 {
      font-size: 2.5rem !important;
    }
  }
}

@media all and (max-width: $mobile_break) {
  #header:has(.header__image):has(#header__suchprofile) {
    height: 45rem;
  }
  .header__desktopheadlines {
    display: none;
  }
  .header--darkblue-withouticon {
    height: 200px !important;
    .header__desktopheadlines {
      display: block;
    }
    h1 {
      padding: 1rem !important;
    }
  }

	#header {

		.header__suchprofile--immos{
			margin-right: -0.8rem;
		}

		.header__content-wrapper {
			h1 {
				color: $erste-darkblue;
			}

			ul.list--withcheckmark {
				max-width: 500px;
				margin: 0 auto;
				li {
					background-position: left 7px;
					line-height: 1.5;
					padding-bottom: 1rem;
				}
			}
		}
	}

	.header__content-wrapper.statsheader-mobile {
		top: 7.5rem !important;
		.statistic-card {
			padding: 1rem;
		}
	}
	.header__content-wrapper--top {
		top: 100px !important;
		transform: unset !important;
	}
	.header--immosuche-mobil {
		min-height: 155px !important;
		background-color: unset !important;
		height: fit-content !important;
		.MuiPaper-root,
		.accordion--searchform.Mui-expanded {
			width: calc(100% - 3rem);
			margin: 6rem auto 0;
		}
		.header__gradient,
		.header__image {
			display: none;
		}
	}
	.header--slider {
		height: 495px !important;
	}
}

.statsheader-mobile {
  min-width: calc(100vw - 1.6rem);
  margin-left: -0.5rem;
  .header__content {
    display: flex;
    width: 100%;
    position: relative !important;
  }
}

.statsheader-container {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  padding-top: 0 !important;
  .header__content {
    height: 100%;
  }

  .chart-wrapper--vertical {
    &.desktop {
      height: auto;
    }
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }

  .statistic-card-wrapper.clickchart {
    margin-top: 5rem;
    height: fit-content;
    width: 100vw;
    max-width: 700px;
  }

  .statistic-card-wrapper {
    padding: 1rem;
    border-radius: 1rem;
    background-color: rgb(255 255 255 / 30%);
    backdrop-filter: blur(5px);

    h3 {
      margin-top: 0;
    }

    &.clickchart button {
      align-self: center;
    }

    
  }

  .statistic-card {
    z-index: 3;
    background-color: rgb(255 255 255 / 30%);
    backdrop-filter: blur(5px);
    margin-bottom: 0 !important;

    .statistic-card__chart {
      width: 100%;
      flex: 0 0 100%;
      margin-bottom: 0;
    }
  }
  h1 {
    font-weight: 700;
    text-align: center;
    font-size: 3.4em;
    margin-bottom: 0;
  }

  h3 p {
    color: white;
  }
  h3 {
    margin-bottom: 0;
  }

  canvas {
    margin: 20px 0;
  }
  .text-link {
    margin-bottom: 0;
    text-decoration: underline;
    cursor: pointer;
  }
  .statsheader-container__headline {
    font-size: 4rem;
    font-weight: 600;
    position: absolute;
    margin: 0;
    padding: 0;
    transform: translate(100%, 50%);
  }
}

.statsheader-container--final {
  height: 365px;
  .statistic-card {
    border-radius: 12px;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }
  .statsheader-container--final__left {
    width: 64%;
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .statistic-card {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 0.25rem;
    }
  }
  .statsheader-container--final__right {
    width: 34%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    .statistic-card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
    }
  }
}

.documentsheader-container {
  z-index: 99;
  position: relative;
  .documents-card {
    z-index: 3;
    width: 100%;
    background-color: rgb(255 255 255 / 30%);
    backdrop-filter: blur(5px);
    padding: 1rem;
    margin-bottom: 2rem;
    border-radius: 1rem;

    .documents-card--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 300px;

      svg {
        margin-top: 4rem;
      }

      span{
        font-size: 30pt;
        font-weight: bold;
      }
    }
  }
}

.statistic-card__filterarea {
  display: flex;
  flex-direction: row;
  align-items: center;

  .filtermenu {
    .filtermenu__item {
      height: 30px;
      margin-top: 5px;
    }
  }

  h4 {
    margin-bottom: 17px;
    margin-right: 1rem;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  .rs-picker {
    margin-left: 0.5rem;

    .rs-picker-input-group {
      border-radius: 100%;
      aspect-ratio: 1;
    }
  }

  .rs-picker.active {
    .rs-picker-input-group {
      background-color: $erste-blue;

      span {
        svg {
          fill: white;
        }
      }
    }
  }

  .rs-picker-input-group {
    .rs-date-range-input {
      display: none !important;
    }
  }
}

.chartinfo-container {
  margin-bottom: 30px;
  flex-direction: row;

  .chartinfo__inverted {
    color: white;
  }
}

@media all and (max-width: $mobile_break) {
  .statsheader-container {
    .card {
      border: 0;

      .statistic-card__header {
        border: 0;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }

  .statistic-card__filterarea {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-top: 0 !important;
    margin-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;

    h4 {
      margin-bottom: 0;
    }

  }
  
  .chartinfo-container {
    display: flex;
    flex-direction: column;
  }

  .documentsheader-container {
    .documents-card {
      .documents-card--content {
        span{
          text-align: center;
          font-size: 20pt;
          font-weight: bold;
        }
      }
    }
  }
}

.header--withimage {
  h2, h3, p, span {
    color: $erste-darkblue;
  }
}

@media all and (max-width: $mobile_break) {
  .statsheader-container {
    &__headline {
      transform: translate(140%, 50%) !important;
      font-size: 4rem !important;
    }
  }
  .header--immosuche-mobil {
    height: fit-content !important;
  }
}

@media (max-width: $mobile_break) {
  #header.header--immosuche,
	#header.header--kaufen,
  #header.header--verkaufen,
  #header.header--home {
    .header__content-wrapper {
      h1,
      h2 {
        color: white;
        font-size: 2rem;
        text-shadow: 0 0 4px $darkgray;
      }
      &.header__content-wrapper--top {
        h1,
        h2 {
          color: $erste-darkblue;
          font-size: 2rem;
          text-shadow: none;
        }
      }
    }
  }

  #header.header--home {
    .header__content-wrapper {
      left: 0 !important;
    }
    .header__ctabuttons {
      position: absolute;
      width: 100%;
      top: 14rem;
      height: 7rem;
      display: flex !important;
      flex-direction: column;
      place-content: flex-end;
      .button {
        height: fit-content !important;
        width: fit-content;
        align-self: center;
      }
    }
    .slick-dots {
      margin: 1rem 0 0.5rem 0;
    }
  }

  #header.header--withimage {
    .header__content-wrapper {
      left: 1.75rem;
      text-align: center;
      top: 6rem;

      h1 {
        font-size: 1.75rem;
      }
    }
  }
}

@media all and (min-width: $mobile_break) {
  #header:has(.header__image) {
    height: 45rem;
  }
  #header {
    height: 20rem;
  }
  .header--slider {
    .slick-slider {
      padding-bottom: 3rem;
      .slick-dots {
        bottom: 75px;
      }
    }
  }
  .immosuche__ergebnisheader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    h2 {
      margin-bottom: 1.5rem;
    }
    .form__field {
      display: flex;
      align-items: center;
      label {
        width: 125px;
      }
      .MuiInputBase-root {
        width: 200px;
      }
    }
  }
  #header.header--default  {
    .header__content-wrapper#header-dashboard4 {
      align-items: flex-start;
      flex-direction: row;
      left: calc((100% - $max_tablet_width) / 2);
      svg {
        margin-top: 0.5rem;
        width: 8rem;
      }
    }
  }
}

@media all and (min-width: 700px) {
  .statistic-card-wrapper.clickchart {
    margin-left: calc((100vw - 700px) / 2);
  }
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
  .header--slider {
    .header__content-wrapper {
      left: unset !important;
    }
  }
}

@media all and (min-width: 951px) and (max-width: 1000px) {
  #header .header__content-wrapper {
    left: 3.2rem;
  }
}

@media all and (min-width: 1201px) and (max-width: 1270px) {
  #header .header__content-wrapper {
    left: 3.2rem;
  }
}

@media all and (min-width: 950px) and (max-width: 1030px) {
  .header__suchprofile--immos {
    .slick-slider {
      margin-top: -3.5rem;
    } 
  }
}

@media all and (min-width: 1031px) and (max-width: 1199px) {
  .header__suchprofile__links {
    padding-top: 0 !important;
  }
}

@media all and (min-width: $desktop_break) {
  #header.header--default  {
    .header__content-wrapper#header-dashboard4 {
      left: calc((100% - 1170px) / 2);
      svg {
        width: fit-content;
      }
      p {
        top: unset;
        text-align: left;
      }
    }
  }
}

.header__suchprofile__links {
  .textlink {
    white-space: nowrap;
  }
} 

.header--fit-content {
  height: fit-content !important;
}

.header__logo-wrapper {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
}
.header__demoversioninfo {
	font-size: 1.2rem;
	font-weight: 500 !important;
	margin-left: 0.3rem;
	margin-top: 3px;
	color: #fff !important;
}