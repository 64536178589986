@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

#sreal-kundenportal-root {
.fullWidthContent {
    background-color: $turquoise;
    p, h2 {
        max-width: $max_desktop_width;
        margin-left: auto;
        margin-right: auto;
    }
    img {
        width: 65%;
        max-width: $max_desktop_width;
        margin-bottom: -4px;
    }
    .w-desktop--center {
        padding-top: 0;
    }
	}
}