$erste-lightblue: #ace0f9;
$erste-lightblue2: #4ba2d5;
$erste-lightblue3: #d0e0fb;
$erste-blue: #2870ED;
$erste-blue-hover: #064ea1;
$erste-dark-blue: #064EA1;
$erste-darkblue: #1e3163;
$default-darkblue: #245375;

$footer-blue: #bce4fa;
$logo-blue: #065388;

$text-blue: #0B1F42;
$orange: #ff6129;
$orange-hover: #d25200;

$verylightgray: #f4f4f4;
$cardlightgray: #f4f6fa;
$lightgray: #e4e4e4;
$lightmidgray: #eef0f4;
$midgray: #b7b7b7;
$cardmidgray: #e8e9ef;
$darkgray: #313a45;
$lightstone: #7591AB;

$deactiv: #A3B5C9;
$deactiv_input: #707070;

$lightgray-backgrounds: #F4F6FA;
$gray: #303030;

$green: #028661; //dashboard Angebote, Angebote & Favoriten
$apple: #0EB43F;

$light-aubergine: #B17EAD;
$light-blue: #65B8FF;
$light-stone: #7598AB;
$light-teal: #65CDCF;

$purple: #721c7a; //dashboards Stats & Statistiken
$newblue: #255375; //dashboard Dokumente
$turquoise: #02a3a4; //immo-drive
$darkerturquoise: #026A6D; //marktbericht
$lightturquoise: #7598AB;
$pink: #eb4c79; //news;
$red: #e72222; //dot-unread

$darkturquoise: #036A6D;
$darkpink: #BC3B51;
$lightred: #FAD3D3;

$dropdownblue: #e9eaf0;
$dropdownborder: #d8d8d8;

$erste-red: #e30613;
$light-red: #FAD3D3;
