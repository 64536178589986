@import '../vars/colors.scss';

.button,
button {
   font-weight: 600;
   border-radius: 4px;
   padding: 0.6rem 1.2rem;
   color: #fff;
   font-size: 1rem;
   box-shadow: none;
   transition: 0.2s ease all;
   cursor: pointer;

   &.button--bigger {
      padding: 1rem;
   }

   &.button--round {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;

      svg,
      .btn-icon {
         margin: 0;
         padding: 0;
      }
   }

   &.button--blue {
      background-color: $erste-blue;
      border: 1px solid $erste-blue;

      &:hover,
      &:active,
      &:focus {
         background-color: $erste-blue-hover;
         border-color: $erste-blue-hover;
         box-shadow: 0px 2px 10px 0.3px #0000004D;
      }
   }
   &.button--white {
      background-color: #fff;
      border: 1px solid #fff;
      color: $erste-blue;
      font-weight: 600;

      &:hover,
      &:active,
      &:focus {
         box-shadow: 0px 4px 12px -4px #00000073;
         background: #FFFFFFD9;
      }
   }
   &.button--wrapper {
      background-color: $erste-lightblue;
   }
   &.button--gray-outline {
      background-color: transparent;
      border: 2px solid $midgray;
      color: $erste-blue;

      &:hover,
      &:active,
      &:focus {
         background-color: rgba(26, 103, 210, 0.1);
         box-shadow: 0px 2px 10px 0.3px #0000004D;
      }
   }
   &.button--white-outline {
      background-color: transparent;
      border: 2px solid #ffffff50;;
      color: white;
      font-weight: 600;

      &:hover,
      &:active,
      &:focus {
         background-color: #0000001A;
         box-shadow: 0px 2px 10px 0.3px #0000004D;
      }
   }
   &.button--orange {
      background-color: $orange;
      border: 1px solid $orange;
      color: #fff;

      &:hover,
      &:active{
         background-color: $orange-hover;
         border-color: $orange-hover;
         box-shadow: 0px 2px 10px 0.3px #0000004D;
      }
      &:focus {
         outline: none;
      }
      &.button--fitcontent {
         width: fit-content;
         align-self: center;
      }
   }
   
   &.button--glass {
      background-color: transparent;
      border: 2px solid #fff;

			&:hover, &:active {
				background-color: rgba(255,255,255,0.25);
				box-shadow: 0px 2px 10px 0.3px #0000004D;
      }
   }

   &.button--glass-thin {
      background-color: transparent;
      border: 1px solid #fff;
   }

   &.button--withicon {
      vertical-align: middle;

      span {
         line-height: 1;
      }

      svg,
      .btn-icon {
         width: auto;
         height: 16px;
         margin-right: 8px;
      }

      .btn-icon--hover {
         display: none;
      }

      &:hover {
         svg.btn-icon {
            display: none;
         }
         svg.btn-icon--hover {
            display: inline-block;
         }
      }
   }

   &.button--mobil {
      display: block;
   }

   &.button--desktop {
      display: none;
   }

   &.button--loading {
      background-color: $midgray;
      border-color: $midgray;
      cursor: not-allowed;
   }

   &.button--loading:hover,
   &.button--loading:focus {
      background-color: $midgray;
      border-color: $midgray;
   }

   @media all and (min-width: $mobile_break) {
      &.button--mobil {
         display: none;
      }

      &.button--desktop {
         display: block;
      }
   }

   &:disabled,
   &:disabled:hover {
      cursor: initial;
      background: #5C7999A6;
      border-color: transparent;
      color: white;
   }
   &.button--white-outline:disabled,
   &.button--white-outline:disabled:hover {
      color:white;
      background: #5C799999;
   }
}

.button:hover {
   text-decoration: none;
}

.MuiDialog-paper {
   .button-panel {
      .button {
         width: 100%;
      }
      .textlink {
         margin: 0.5rem auto;
      }
   }
}

.button-panel {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   justify-content: flex-start;
   align-items: flex-start;
   margin: 20px 0;
   width: 100%;

	 &.button-panel-mobilefullwidth {
		.button {
			width: 100%;
			margin-right: 0;
			text-align: center;
		}
	 }

   .button {
      margin-right: 10px;
      margin-bottom: 10px;
   }
}

.buttonswitcher--wrapper {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   width: min-content;
   border: 1px solid $erste-blue;
   border-radius: 50px;
   background-color: lighten($erste-blue, 10%);

   .button {
      border: 0;
      border-radius: 50px;
      background-color: inherit;
      color: #fff;
      margin: 0;
      padding: 0.25rem 1rem;
      text-decoration: none;

      &.active {
         /* border: 1px solid $erste-blue; */
         background-color: #fff;
         color: $erste-blue;
      }

      &:first-child {
         border-right: 0px;
      }

      &:last-child {
         border-left: 0p15px;
      }
   }
}

@media all and (max-width: $desktop_break) {
   .header__buttonright .buttonswitcher--wrapper, .button--register-menu, .button--login-menu {
      display: none;
   }
}

@media all and (min-width: $mobile_break) {
   .button-desktop--white-outline {
      background-color: transparent;
      border: 2px solid white !important;
      color: white;

      &:hover,
      &:active,
      &:focus {
         background-color: $erste-blue;
         color: #fff;
      }
   }

	.button-panel {
		&.button-panel-mobilefullwidth {
			.button {
				width: unset;
				margin-right: 10px;
				text-align: left;
			}
		}
	}
}
