/* what is this? */
.box {
    width: 200px;
    height: 200px;
    background: #aaa;
}

.loading-animation {
	span {
		font-weight: bold;
		opacity: 0;
		animation: blink 1.2s infinite;
	}

	span:nth-child(1) {
			animation-delay: 0s;
	}

	span:nth-child(2) {
			animation-delay: 0.4s;
	}

	span:nth-child(3) {
			animation-delay: 0.8s;
	}
}

@keyframes blink {
	0% { opacity: 0; }
	50% { opacity: 1; }
	100% { opacity: 1; }
}