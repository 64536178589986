@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.finanzrechner__formresult {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 1rem;
}

#immodetail .finanzrechner-form__content {
  h2 {
    margin-top: 0;
    text-align: left;
  }
}

#immodetail .finanzrechner-form, .finanzrechner-form {
	color: $erste-darkblue;
	h3 {
		font-size: 1.6em;
		margin-bottom: 0;
	}

	.button-panel {
		margin-bottom: 0;
		.button:last-child {
			margin-bottom: 0;
		}
	}

  .finanzrechner-form__content {
		display: flex;
    flex-direction: column;
    box-sizing: border-box;
		margin-top: 1rem;

    .textlink {
			color: $erste-blue;
    }

		.finanzrechner__result-wrapper {
			display: flex;
			flex-direction: column;
			margin-bottom: 2rem;

			&:first-child {
				margin-top: 1rem;
			}

			&.mobile-hidden {
				display: none;
			}

			span:first-child {
				// headline
				margin-bottom: 5px;
				font-weight: 600;
			}

			span:nth-child(2) {
				// values
				font-size: 1.4em;
				font-weight: 600;
			}
		}

    .form__fielderror {
      color: $erste-red;
    }

    label {
      color: $gray;
      font-weight: 600;
      font-family: Inter;
      font-size: 1rem;
    }

    .form__row--double:last-child {
      margin-bottom: 0;
    }
  }

	p {
		margin-top: 2rem;
	}
}

@media all and (min-width: $mobile_break) and (max-width: $desktop_break) {
	#immodetail .finanzrechner-form, .finanzrechner-form {
		.finanzrechner-form__content {
			.form__row {
				gap: 20px;
				.form__field {
					flex: 0 0 100% !important;
				}
			}
		}
	}
}

@media all and (min-width: $mobile_break) {
	#immodetail .finanzrechner-form, .finanzrechner-form {
		.finanzrechner-form__content {
			margin-top: 2rem;
			.finanzrechner__result-wrapper {
				margin-top: 1rem;
				&.desktop-hidden {
					display: none;
				}
				&.mobile-hidden {
					display: flex;
				}
			}

			.finanzrechner-form__content__results {
				display: flex;
				gap: 40px;
			}
		}

		p {
			margin-top: 0;
		}
	}
}
