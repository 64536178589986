@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.stickySidebar-wrapper {
		position: fixed;
    bottom: 0.75rem;
    left: 0.75rem;
    z-index: 1000;

		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;


		.stickySidebar__panel, .stickySidebar__btn {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			text-align: center;
			width: auto;
			border-radius: 16px;
			background-color: $erste-blue;
			box-sizing: border-box;

			span {
				font-size: 0.75rem;
				font-weight: 600;
				line-height: 1.5;
				color: #fff;
				text-align: center;
			}
		}


		.stickySidebar__panel {
			background-color: $erste-dark-blue;
			height: fit-content;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
			margin-left: 0.5rem;
			padding: 0.75rem 0.5rem;

			span {
				padding: 0 0.5rem;
				color: $erste-lightblue3;
				cursor: pointer;


				&.active {
					color: #fff;
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}

		.stickySidebar__btn {			
			height: 100px;
			min-width: 75px;
			transition: all 0.3s ease;
			cursor: pointer;

			svg {
        width: 20px;
        height: 20px;
				margin-bottom: 0.75rem;
    	}

			&:hover {
				background-color: $erste-dark-blue;
			}
		}
}


@media all and (min-width: 420px) {
	.stickySidebar-wrapper {
		.stickySidebar__panel {
			padding: 0.75rem;
		}

		.stickySidebar__btn {
			min-width: 85px;
		}

	}
}

@media all and (min-width: $max_tablet_width) {
	.stickySidebar-wrapper {
		bottom: 20%;
		right: 1.5rem;
		left: unset;

		flex-direction: column;

		.stickySidebar__panel, .stickySidebar__btn {
			width: 100px;
			min-height: 100px;
		}

		.stickySidebar__panel {
			margin-bottom: 30px;
			order: 0;
			flex-direction: column;
			margin-left: 0;

			span {
				width: 100%;
				padding-top: 0.5rem;
				
				&:after {
					content: '';
					display: inline-block;
					width: 50px;
					height: 1px;
					margin: 0 auto;
					border-bottom: 1px solid #fff;	
				}

				&:last-child {
					padding-bottom: 0.5rem;
					&:after {
						display: none;
					}
				}

				&:hover {
					color: #fff;
				}
			}
		}

		.stickySidebar__btn {
			order: 1;
			svg {
        width: 25px;
        height: 25px;
			}
		}

	}

}
