@import '../../../../shared/styles/sreal_default/vars/breaks.scss';
@import '../../../../shared/styles/sreal_default/vars/colors.scss';

.marktberichtForm {
	color: #fff;
	p, label {
		color: #fff;
	}

	.form__row.form__row--double.form__row--double--mobilefull {
		.form__field {
			&.w-60 {
				width: 58%;
				flex: 0 0 58%;
			}

			&.w-40 {
				width: 38%;
				flex: 0 0 38%;
			}

			.form__fielderror {
				color: #fff;
			}
		}

		.form__field--haslist {
			.input-list {
				max-height: 200px;
			}
		}
	}

	.form__field--checkbox {
		.form__fielderror {
			margin: 0;
			font-weight: bold;
		}
	}

	.marktbericht-error {
		.marktbericht-errorbox {
			width: 100%;
			margin-top: 1rem;
			background-color: $light-red;
			padding: 1.5rem;
			border-radius: 0.5rem;
			margin-top: 6rem;
			margin-bottom: 7rem;

			p {
				margin: 0;
				color: red;
			}
		}
	}

	.button-panel {
		align-items: normal;
		margin-bottom: 0;
		.button {
			margin-right: 2rem;
			margin-bottom: 1rem;
		}
	}

	.marktbericht-errortext-notfound {
		color: #FAD3D3;
		margin: 0;

		.textlink {
			color: #FAD3D3;
			text-decoration: underline;
		}
	}
}

/* ONLY MOBILE */
@media all and (max-width: $mobile_break) {
	.marktberichtForm {
		.form__row.form__row--double.form__row--double--mobilefull {

			.form__field {
				width: 100%;
				flex: 0 0 100%;
			}
		}
	}
}


@media all and (min-width: $mobile_break) {
	.marktberichtForm {
		.form__row.form__row--double.form__row--double--mobilefull {
			margin-bottom: 20px		
		}

		.button-panel {
			.button {
				margin-bottom: 0;
			}
		}

	
	}
}

@media all and (min-width: $desktop_break) {
	.marktberichtForm {
		.button-panel {
			position: relative;
		}

		.marktbericht-errortext-notfound {
			position: absolute;
			top: -3px;
			left: 240px;
			width: 100%;
		}
	}
}


