@import '../../../../shared/styles/sreal_default/vars/colors.scss';
@import '../../../../shared/styles/sreal_default/vars/breaks.scss';

.stickyButton {
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 30px;
    z-index: 1000;
    background-color: $erste-blue;
    color: white;
    padding-left: 15px;
    border-radius: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    width: 130px;
    transition: width 0.3s ease;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    height: 50px;

    svg {
        width: 25px;
        height: 25px;
    }

    span {
			margin-left: 15px;
    }
}

.stickyButton--withFixedBar {
    bottom: 10.5rem;
}

.stickyButton:hover {
    background-color: $erste-dark-blue;
}

.stickyButton.scrolled {
    width: 50px;
    padding-left: 8px;

    svg{
        margin-left: 0;
        transform: translateX(5px);
    }
}

@media all and (min-width: $mobile_break) {
    .stickyButton--withFixedBar {
        bottom: 6.5rem;
    }
}